import React, {useContext} from "react";
import {useCookies} from 'react-cookie';
import {v4 as uuidv4} from 'uuid';
import FormsApi from "../Service/FormsApiService";
import PaymentsApi from "../Service/PaymentsApiService";
import PagesApi from "../Service/PagesApiService";

const year = 365 * 24 * 60 * 60 * 1000;

interface ProviderValue {
    userId: string;
}

export const AuthContext = React.createContext<ProviderValue>({
    userId: ''
});

export const useAuth = () => useContext(AuthContext);

interface Props {
    children: any;
}

export const AuthProvider = ({children}: Props) => {
    const [cookies, setCookie] = useCookies(['scorpius']);

    const userId = cookies['scorpius'] ? cookies['scorpius'] : uuidv4();
    setCookie('scorpius', userId, {path: '/', expires: new Date(new Date().getTime() + year)});

    // init api
    FormsApi.token = userId;
    PaymentsApi.token = userId;
    PagesApi.token = userId;

    return (
        <AuthContext.Provider value={{userId}}>
            {children}
        </AuthContext.Provider>
    )
};
