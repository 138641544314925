import React, {Fragment} from "react";
import Field from "./Fields/Field";
import {usePage} from "../../../../Provider/PageProvider";
import {useLang} from "../../../../Provider/LangProvider";

const texts : any = {
    en: {
        step2: "Step 2",
        yourInformation: "Your Information",
        firstName: "First name",
        lastName: "Last name",
        email: "Email Address",
        phone: "Phone Number",
        requiredFirstName: "Please enter your first name",
        requiredLastName: "Please enter your last name",
        requiredEmail: "Please enter a valid email address",
        tipEmail: "Please make sure your email address is valid.",
        requiredPhone: "Please enter your phone",
    },
    es: {
        step2: "PASO 2",
        yourInformation: "TU INFORMACIÓN",
        firstName: "Nombre",
        lastName: "Apellido",
        email: "E-mail",
        phone: "Número de teléfono",
        requiredFirstName: "Por favor, introduce tu nombre",
        requiredLastName: "Por favor, introduce tu apellido",
        requiredEmail: "Por favor, introduce una dirección de correo electrónico válida",
        tipEmail: "Por favor, asegúrate de proporcionar una dirección válida de correo electrónico.",
        requiredPhone: "Por favor, introduce tu teléfono",
    },
    de: {
        step2: "SCHRITT 2",
        yourInformation: "IHRE INFORMATIONEN",
        firstName: "Vorname",
        lastName: "Nachname",
        email: "E-Mail-Adresse",
        phone: "Telefonnummer",
        requiredFirstName: "Bitte geben Sie Ihren Vornamen ein",
        requiredLastName: "Bitte geben Sie ihren Nachnamen ein",
        requiredEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        tipEmail: "Bitte stellen Sie sicher, dass Ihre E-Mail-Adresse gültig ist.",
        requiredPhone: "Bitte geben Sie Ihre Telefonnummer ein",
    },
}

export default function CustomerInformation() {
    const {contact, onContactChange, emailFieldRef} = usePage();
    const {lang} = useLang();

    if (!contact) {
        return <Fragment/>
    }
    return <Fragment>
        <div ref={emailFieldRef} style={{marginTop: -30, marginBottom: 30, lineHeight: 0}}>&nbsp;</div>

        <h2>{texts[lang].step2}: <span>{texts[lang].yourInformation}</span></h2>
        <div className="double a">
            <Field val={contact.firstName} onChange={onContactChange} title={texts[lang].firstName} fieldKey={"firstName"}
                   required={true}
                   errorText={texts[lang].requiredFirstName}/>
            <Field val={contact.lastName} onChange={onContactChange} title={texts[lang].lastName} fieldKey={"lastName"}
                   required={true}
                   errorText={texts[lang].requiredLastName}/>
        </div>
        <div className="double a">
            <Field val={contact.email} onChange={onContactChange} title={texts[lang].email} fieldKey={"email"}
                   required={true} tipText={texts[lang].tipEmail}
                   errorText={texts[lang].requiredEmail} type={"email"}/>
            <Field val={contact.phone} onChange={onContactChange} title={texts[lang].phone} fieldKey={"phone"}
                   required={true}
                   errorText={texts[lang].requiredPhone}/>
        </div>
    </Fragment>
}
