import Config from "../Constants/Config";

class FormsApiService {
    set token(value: string) {
        this._token = value;
    }
    private _token: string;

    constructor() {
        this._token = '';
    }

    defaultRequestOptions = () => {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this._token
            },
        }
    };

    init() {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(Config.backendUrl + '/app/auth/init', requestOptions).then(res => res.json());
    }



}

const FormsApi = new FormsApiService();
export default FormsApi;
