import React from "react";

import GoogleImage from "../../../../Assets/google.png";
import McAfeeImage from "../../../../Assets/mcafee.jpg";
import TrusteImage from "../../../../Assets/truste.jpg";

interface Logo {
    source: string;
    width: number;
    height: number;
    placeholder: string;
}

export default function ListLogo() {
    const logos = [
        {source: McAfeeImage, width: 77, height: 30, placeholder: ''},
        {source: TrusteImage, width: 93, height: 30, placeholder: ''},
        {source: GoogleImage, width: 100, height: 30, placeholder: ''},
    ];

    return (
        <ul className="list-logo">
            {logos.map((logo: Logo, index: number) => {
                return (<li key={"logo-" + index}><img src={logo.source} alt={logo.placeholder} width={logo.width}
                                                       height={logo.height}/></li>)
            })}
        </ul>
    )
}
