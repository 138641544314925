import React from "react";
import {useParams} from 'react-router-dom'
import {PageProvider} from "../Provider/PageProvider";

export default function CheckoutThemePage() {
    const {url} = useParams();
    return (
        <PageProvider url={url}/>
    )
}
