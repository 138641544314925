import React, {Fragment} from "react";
import StripeCheckoutWrapper from "./StripeCheckoutWrapper";
import {usePage} from "../../../../../Provider/PageProvider";

import CreditCardImage from "../../../../../Assets/creditcards.png";
import PaypalImage from "../../../../../Assets/paypal.png";
import PaypalCheckoutWrapper from "./PaypalCheckoutWrapper";
import {usePageView} from "../../../../../Provider/PageViewProvider";
import {useLang} from "../../../../../Provider/LangProvider";

const isPraytify = () => {
    return window.location.host === 'checkout.praytify.com' || window.location.host === 'checkout.blessify.com';
}

const texts: any = {
    en: {
        step4: isPraytify() ? "Step 3" : "Step 4",
        paymentInfo: "Payment Information",
        creditCard: "Credit Card",
        paypal: "PayPal",
        afterClicking: "After clicking \"Place the Order\", you will be redirected to PayPal to complete your purchase securely."
    },
    es: {
        step4: "PASO 4",
        paymentInfo: "INFORMACIÓN DE PAGO",
        creditCard: "Tarjeta de crédito",
        paypal: "PayPal",
        afterClicking: "Después de hacer clic en \"Realizar el pedido\", será redirigido a PayPal para completar su compra de forma segura."
    },
    de: {
        step4: "SCHRITT 4",
        paymentInfo: "ZAHLUNGSINFORMATIONEN",
        creditCard: "Kreditkarte",
        paypal: "PayPal",
        afterClicking: "Nachdem Sie auf \"Bestellung aufgeben\" geklickt haben, werden Sie zu PayPal weitergeleitet, um Ihren Kauf sicher abzuschließen."
    },
}

export default function CheckoutCard() {
    const {registerEvent} = usePageView();
    const {paymentMethod, setPaymentMethod, cardNumberRef} = usePage();
    const {lang} = useLang();

    const paymentSwitch = (method: string) => {
        setPaymentMethod(method);
        registerEvent('payment_set_' + method, 1);
    }

    return (
        <Fragment>
            <h2 ref={cardNumberRef}>{texts[lang].step4}: <span>{texts[lang].paymentInfo}</span></h2>
            <ul className="list-payment">
                <li>
                    <input type="radio" id="stripe" name="payment-method" checked={paymentMethod === 'credit'}
                           onClick={() => paymentSwitch('credit')}/> <label
                    htmlFor="stripe">{texts[lang].creditCard}</label>
                    <div className="triple a">
                        {paymentMethod === 'credit' && <StripeCheckoutWrapper active={paymentMethod === 'credit'}/>}
                    </div>
                    <img src={CreditCardImage} alt="" width="172" height="15"/>
                </li>
                <li><input type="radio" id="paypal-radio" name="payment-method" checked={paymentMethod === 'paypal'}
                           onClick={() => paymentSwitch('paypal')}/> <label
                    htmlFor="paypal-radio">{texts[lang].paypal}</label>
                    {paymentMethod === 'paypal' && <PaypalCheckoutWrapper active={paymentMethod === 'paypal'}/>}
                    <div>
                        <p className={"color-dove"}>
                            {texts[lang].afterClicking}
                        </p>
                    </div>
                    <img src={PaypalImage} alt="" width="88" height="25"/></li>
            </ul>
        </Fragment>
    )

}
