import React, {useContext} from "react";

interface ProviderValue {
    lang: string,
}

export const LangContext = React.createContext<ProviderValue>({


    lang: 'en'
});

export const useLang = () => useContext(LangContext);

interface Props {
    lang: string;
    children: any
}

export const LangProvider = ({lang, children}: Props) => {

    return (
        <LangContext.Provider value={{
            lang
        }}>
            {children}
        </LangContext.Provider>
    )
};
