import React, {Fragment} from "react";
import {UpgradeOrderProduct} from "../../../../../Types/Objects";
import {SelectUpgradeProductCart, usePage} from "../../../../../Provider/PageProvider";
import {isMobileOnly, isTablet} from 'react-device-detect';
import {usePageView} from "../../../../../Provider/PageViewProvider";
import {useLang} from "../../../../../Provider/LangProvider";

interface Props {
    product: UpgradeOrderProduct;
}

const texts: any = {
    en: {
        save: "Save",
        byOrdering: "By Ordering",
        added: "Added",
        addProduct: "Add product",
        total: "Total"
    },
    es: {
        save: "Ahorra un",
        byOrdering: "pidiendo",
        added: "Añadido",
        addProduct: "Añadir producto",
        total: "Total"
    },
    de: {
        save: "Sparen Sie",
        byOrdering: "bei Bestellung",
        added: "Hinzugefügt",
        addProduct: "Produkt hinzufügen",
        total: "Summe"
    },
}

const _defQuantity = 2;

export default function Product({product}: Props) {
    const defQuantity = (product.defaultQ ? product.defaultQ : _defQuantity);
    const {lang} = useLang();
    const {registerEvent} = usePageView();
    const {
        addSelectedUpgradeProduct,
        removeSelectedUpgradeProduct,
        selectedUpgradeProducts,
        design,
        currencySymbol,
    } = usePage();

    const onAdd = () => {
        registerEvent('select_upgrade', product.id);
        addSelectedUpgradeProduct(product, defQuantity);
    }

    const onRemove = () => {
        registerEvent('deselect_upgrade', product.id);
        removeSelectedUpgradeProduct(product);
    }


    const onCart = selectedUpgradeProducts.filter((i: SelectUpgradeProductCart) => i.product === product);
    const selected = onCart.length >= 1;

    const q = selected ? onCart[0].quantity : defQuantity;
    let price = product.price;
    let saveText = "";
    product.priceItems.map((p) => {
        if (q >= p.quantity) {
            price = p.price;
        }
        if (q + 1 >= p.quantity) {
            saveText = p.text;
        }
    });
    const total = price * q;


    const onPlus = () => {
        registerEvent('select_upgrade_plus', product.id);
        addSelectedUpgradeProduct(product, q + 1);
    }
    const onMinus = () => {
        registerEvent('select_upgrade_minus', product.id);
        addSelectedUpgradeProduct(product, Math.max(1, q - 1));
    }
    const onQ = (q: number) => {
        registerEvent('select_upgrade_quantity', product.id);
        addSelectedUpgradeProduct(product, Math.max(1, q));
    }

    const saveContent = () => {
        return (
            product.priceItems && product.priceItems.length > 0 ?
                <span className={"save-content"}><svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
<path
    d="M17.1563 0H10.4063C9.94276 0 9.29364 0.268875 8.96626 0.59625L0.597389 8.96513C0.439588 9.12367 0.350998 9.33825 0.350998 9.56194C0.350998 9.78563 0.439588 10.0002 0.597389 10.1587L7.84126 17.4026C7.9998 17.5604 8.21439 17.649 8.43808 17.649C8.66177 17.649 8.87635 17.5604 9.03489 17.4026L17.4038 9.03375C17.7323 8.70525 18 8.05725 18 7.59375V0.84375C17.9991 0.620247 17.9099 0.406152 17.7519 0.24811C17.5939 0.0900689 17.3798 0.00088869 17.1563 0V0ZM12.9375 6.75C12.7158 6.74993 12.4963 6.70619 12.2916 6.62129C12.0868 6.53639 11.9007 6.41198 11.744 6.25518C11.5873 6.09838 11.4631 5.91224 11.3783 5.70741C11.2935 5.50258 11.2499 5.28305 11.25 5.06138C11.2501 4.8397 11.2938 4.6202 11.3787 4.41542C11.4636 4.21065 11.588 4.0246 11.7448 3.8679C11.9016 3.7112 12.0878 3.58692 12.2926 3.50216C12.4974 3.41739 12.717 3.3738 12.9386 3.37388C13.3863 3.37402 13.8156 3.55202 14.1321 3.86869C14.4486 4.18537 14.6263 4.6148 14.6261 5.0625C14.626 5.5102 14.448 5.93951 14.1313 6.25598C13.8146 6.57244 13.3852 6.75015 12.9375 6.75Z"
    fill="#F06565"/>
</g><defs>
<clipPath id="clip0"><rect width="18" height="18" fill="white"/></clipPath></defs></svg>
                    {texts[lang].save} {saveText}% {texts[lang].byOrdering} {q + 1}
                                </span> : <Fragment/>
        )
    }

    const addToCartTitle = (!!product.addToCartTitle ?
        <span className={"add-to-cart-title"}>{product.addToCartTitle}</span> : <Fragment/>);

    const btns = () => {
        return (
            <Fragment>
                {selected ?
                    <span className="link-btn"><a href="/remove-from-cart" onClick={e => {
                        e.preventDefault();
                        onRemove();
                    }}>{texts[lang].added}</a></span> :
                    <span className="link-btn"><a href="/add-to-cart" onClick={e => {
                        e.preventDefault();
                        onAdd();
                    }}>{texts[lang].addProduct}</a></span>}
            </Fragment>
        )
    }

    const qInput = () => {
        return (
            <div className={"semantic-number"}>
                <input type="number" id="mpa" name="mpa" value={q.toString()}
                       onChange={e => {
                           onQ(parseInt(e.target.value, 10))
                       }}
                       required/>
                <a href="/plus" onClick={e => {
                    e.preventDefault();
                    // addQuantity()
                    onPlus();
                }} className="btn plu" role="button">+</a>
                <a href="/min" onClick={e => {
                    e.preventDefault();
                    // minQuantity()
                    onMinus();
                }} className="btn min" role="button">-</a>
            </div>
        )
    }

    const pcTabletPrice = () => {
        return (
            <span className="price">{currencySymbol}{price.toFixed(2)}
                <span className={"total-price"}>{texts[lang].total}: {currencySymbol}{total.toFixed(2)}</span></span>
        )
    }

    const tabletRow = () => {
        return (
            <div style={{float: "right"}}>
            <span className={"row"} style={{display: 'flex', alignItems: 'center'}}>
            {pcTabletPrice()}
                {qInput()}
                {btns()}
        </span>
            </div>
        )
    }

    return (
        <li>

            {isMobileOnly ?
                <a href="/add-to-cart" onClick={e => {
                    e.preventDefault();
                    selected ? onRemove() : onAdd();
                }} style={{flexDirection: "column", alignItems: 'stretch'}}>
                    <Fragment>
                        <span className={"row"}>
                            {product.title}
                        </span>
                        <span className={"row"} style={{marginTop: 10}}>
                            <span className="overlay-a">{product.description}</span>
                        </span>

                        <span className={"row"}
                              style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                            <p className="price" style={{marginLeft: 0}}>{currencySymbol}{price.toFixed(2)}
                                <span
                                    className={"total-price"}>{texts[lang].total}: {currencySymbol}{total.toFixed(2)}</span>
                            </p>
                            <img src={product.image} alt={product.title} width="220" height="165"/>
                        </span>
                        {!!addToCartTitle && <span className={"row"}>{addToCartTitle}</span>}
                    </Fragment>
                </a>
                :
                <Fragment>
                    {addToCartTitle}
                    <a href="/add-to-cart" onClick={e => {
                        e.preventDefault();
                        if (isTablet) {
                            return;
                        }
                        selected ? onRemove() : onAdd();
                    }}>
                        <Fragment>
                            <img src={product.image} alt={product.title} width="125"
                                 height="95"/>

                            <span>
                                {saveContent()}
                                {product.title} {design !== 1 &&
                            <span className="overlay-a">{product.description}</span>}
                                {isTablet && tabletRow()}
                            </span>

                            {!isTablet && pcTabletPrice()}
                        </Fragment>
                    </a>


                    {!isTablet && qInput()}
                </Fragment>
            }

            {isMobileOnly ?
                <Fragment>
                    <div className={'mobile-upgrade-btn-container'}>
                        {qInput()}
                        {btns()}
                    </div>
                    {saveContent()}
                </Fragment> : isTablet ? <Fragment></Fragment> : btns()}

        </li>
    )
}
