import React, {useState} from "react";
import PagesApi from "../../../../../Service/PagesApiService";
import {usePage} from "../../../../../Provider/PageProvider";

export default function DiscountCoupon() {
    const {discount, setDiscount} = usePage();

    const [code, setCode] = useState('');

    const checkCode = (e: any) => {
        e.preventDefault();

        PagesApi.checkDiscount(code).then((res) => {
            if (res.s) {
                setDiscount({v: res.v, p: res.p, code: code});
            } else {
                setDiscount(undefined);
            }
        });
    }

    return (
        <li>
            Got coupon?
            <span className={"coupon-code"}>
                    <input value={code} onChange={e => setCode(e.target.value)}/>
                    <button type={"button"} onClick={checkCode}>{!!discount ? "Applied" : "Apply"}</button>
                </span>
        </li>
    )
}
