import React from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import CheckoutThemePage from "./Pages/CheckoutThemePage";
import {AuthProvider} from "./Provider/AuthProvider";
import UpsalePage from "./Themes/GreenPixel/Components/Content/Upsale/UpsalePage";
import PaymentConfirmPage from "./Themes/GreenPixel/Components/Content/Payment/PaymentConfirmPage";
import ConfirmPage from "./Themes/GreenPixel/Components/Content/Confirm/ConfirmPage";
import PaymentUpsaleConfirmPage from "./Themes/GreenPixel/Components/Content/Payment/PaymentUpsaleConfirmPage";
import Luckytail from "./Front/Luckytail";
import KidyStars from "./Front/KidyStars";
import Catsizor from "./Front/Catsizor";
import Getmagnetto from "./Front/Getmagnetto";
import {PageViewProvider} from './Provider/PageViewProvider';
import Luckytail2 from "./Front/Luckytail2";
import LedChargy from "./Front/LedChargy";
import CloudPrint from "./Front/CloudPrint";
import Teeth from "./Front/Teeth";
import Dogfasting from "./Front/Dogfasting";
import PaymentErrorPage from "./Pages/PaymentErrorPage";
import Praytify from "./Front/Praytify";

function App() {

    const comp = () => {
        if (window.location.host === 'checkout.kidystars.com' || window.location.host === 'checkout.uvdrawing.com'
            || window.location.host === 'checkout-us.kidystars.com' || window.location.host === 'checkout-us.uvdrawing.com'
        ) {
            return KidyStars;
        } else if (window.location.host === 'checkout.myprintmoments.com'
        ) {
            return CloudPrint;
        } else if (window.location.host === 'checkout.catsizor.com'
            || window.location.host === 'checkout-us.catsizor.com'
        ) {
            return Catsizor;
        } else if (window.location.host === 'checkout.getmagnetto.com'
            || window.location.host === 'checkout-us.getmagnetto.com'
        ) {
            return Getmagnetto;
        } else if (window.location.host === 'checkout.lucky-tail.com' ||
            window.location.host === 'checkout-us.lucky-tail.com'
        ) {
            return Luckytail2;
        } else if (window.location.host === 'checkout.ledchargy.com' ||
            window.location.host === 'checkout-us.ledchargy.com'
        ) {
            return LedChargy;
        } else if (window.location.host === 'checkout.myteethacademy.com') {
            return Teeth;
        } else if (window.location.host === 'checkout.dogfasting.com') {
            return Dogfasting;
        } else if (window.location.host === 'checkout.praytify.com' || window.location.host === 'checkout.blessify.com') {
            return Praytify;
        } else {
            return Luckytail;
        }
    }

    return (
        <AuthProvider>
            <BrowserRouter basename={'/'}>
                <Switch>
                    <PageViewProvider>
                        <Route component={comp()} exact
                               path={"/"}/>
                        <Route component={comp()} exact
                               path={"/checkout"}/>

                        <Route component={CheckoutThemePage} path={"/cart/:url"}/>
                        <Route component={ConfirmPage} path={"/order/confirm/:serial"}/>

                        <Route component={UpsalePage} path={"/order/up/:serial"}/>

                        <Route component={PaymentConfirmPage} path={"/payment/confirm/:serial"}/>

                        <Route component={PaymentUpsaleConfirmPage}
                               path={"/payment-upsale/confirm/:serial/:mainSerial"}/>

                        <Route component={PaymentErrorPage} path={'/payment/error/:serial'}/>
                    </PageViewProvider>
                </Switch>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
