import React, {Fragment, useEffect, useState} from "react";
import {Product, ProductExtraImage} from "../../../../../Types/Objects";
import StarRating from "../Stars/StarRating";
import {useCart} from "../../../../../Provider/CartProvider";
import {usePageView} from "../../../../../Provider/PageViewProvider";
import {useLang} from "../../../../../Provider/LangProvider";
import videoBtn from "../../../../../Assets/play_button.png"
import ReactPlayer from 'react-player'
import UpsaleVideoPlayer from "./UpsaleVideoPlayer";

interface Props {
    product: Product,
    removeFromCartItem: (product: Product) => void,
    isInCart: boolean,
    changeCartItemQuantity: (int: number, productId: number) => void,
    currencySymbol: string,
}

const texts: any = {
    en: {
        addToMyOrder: "Yes, Add this to My Order",
        removeFromCart: "Remove from cart",
        scrollDown: "Scroll down to continue",
        mostPopular: "Most popular",
        rating: "Rating",
        votes: "Votes"
    },
    es: {
        addToMyOrder: "SÍ, AÑADIR A MI PEDIDO",
        removeFromCart: "Quitar del carrito",
        scrollDown: "Desplazar hacia abajo para continuar",
        mostPopular: "Lo más populuar",
        rating: "Clasificación",
        votes: "Votos"
    },
    de: {
        addToMyOrder: "JA, DIES ZU MEINER BESTELLUNG HINZUFÜGEN",
        removeFromCart: "Aus dem Warenkorb entfernen",
        scrollDown: "Scrollen Sie nach unten, um fortzufahren",
        mostPopular: "am beliebtesten",
        rating: "Bewertung",
        votes: "Stimmen"
    },
}

export default function UpsaleProduct({
                                          product,
                                          removeFromCartItem,
                                          changeCartItemQuantity,
                                          isInCart,
                                          currencySymbol
                                      }: Props) {
    const {lang} = useLang();

    const [mainImage, setMainImage] = useState(product.image);

    const [rating, setRating] = useState('');
    const [votes, setVotes] = useState('');

    const {registerEvent} = usePageView();
    const [quantity, setQuantity] = useState(1);
    const {addCartItem} = useCart();

    const addQuantity = () => {
        setQuantity(quantity + 1);

        registerEvent('upsale_product_increment', product.id);
    }
    const minQuantity = () => {
        const newQ = Math.max(1, quantity - 1);
        setQuantity(newQ);

        registerEvent('upsale_product_decrease', product.id);
    }

    const onQunatityChange = () => {
        if (!isInCart && quantity > 1) {
            addCartItem(product, quantity);
        } else {
            changeCartItemQuantity(quantity, product.productId);
        }
    }

    useEffect(onQunatityChange, [quantity]);

    const description = !!product.description ? product.description.split("\n") : [];
    const extraDescription = !!product.extraDescription ? product.extraDescription.split("\n") : [];

    const setRatingVotes = () => {
        setRating(((Math.floor(Math.random() * 5) + 46) / 10).toFixed(1));
        setVotes(((Math.floor(Math.random() * 3916) + 1032) / 1000).toFixed(3).replace('.', ','));
    }
    useEffect(setRatingVotes, []);

    const imageThumbSize = Math.min(64, Math.floor(360 / (product.extraImages.length + 1)) - 15);

    const wPercent = Math.min(25, Math.floor(100 / (product.extraImages.length + 1)) - 0);

    return (
        <form action="./" method="post" className="module-product">
            <div>
                <figure>
                    {mainImage.indexOf('youtu') >= 0 ? <UpsaleVideoPlayer videoUrl={mainImage}/>
                        :
                        <img src={mainImage} alt={product.title} width="360" height="270"/>}


                    {product.extraImages.length > 0 &&
                    <ul className="list-features">
                        <li style={{width: wPercent + '%'}} key={"extra-image-" + product.productId + "-0"}
                            onClick={() => setMainImage(product.image)}>
                            <span className={"img" + (product.image === mainImage ? " active" : "")}>
                                <img src={product.image} alt={product.image} width={imageThumbSize}
                                     height={imageThumbSize}/>
                            </span>
                        </li>
                        {product.extraImages.map((img: ProductExtraImage, index: number) => {
                            return (
                                <li style={{width: wPercent + '%'}}
                                    key={"extra-image-" + product.productId + "-" + index}
                                    onClick={() => setMainImage(img.videoUrl ? img.videoUrl : img.imageUrl)}>
                                    <span
                                        className={"img" + ((img.videoUrl ? img.videoUrl : img.imageUrl) === mainImage ? " active" : "")}>
                                        <img src={img.imageUrl} alt={img.title} width={imageThumbSize}
                                             height={imageThumbSize}/>
                                        {!!img.videoUrl && <img src={videoBtn} className={"upsale-video-btn"}/>}
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                    }
                </figure>
                <p className="price">{currencySymbol}{product.price.toFixed(2)}
                    <span>Reg {currencySymbol}{product.regularPrice.toFixed(2)}</span></p>
                <p>
                    <label htmlFor="mpa">Quantity</label>
                    <div className={"semantic-number"}>
                        <input type="number" id="mpa" name="mpa" value={quantity.toString()}
                               onChange={e => setQuantity(parseInt(e.target.value, 10))} required/>
                        <a href="/plus" onClick={e => {
                            e.preventDefault();
                            addQuantity()
                        }} className="btn plu" role="button">+</a>
                        <a href="/min" onClick={e => {
                            e.preventDefault();
                            minQuantity()
                        }} className="btn min" role="button">-</a>
                    </div>
                </p>
            </div>
            <div>
                <header>
                    <h2>{product.title}
                        {product.isPopular &&
                        <span className="overlay-b"><i className="icon-flame"></i> {texts[lang].mostPopular}</span>}


                    </h2>
                    <StarRating rating={5} asP={true}
                                label={rating + " " + texts[lang].rating + " | " + votes + " " + texts[lang].votes}/>
                </header>
                {description.map((text: string, index: number) => {
                    const className = index === 0 ? "strong m0" : "";
                    return (<p className={className}>{text}</p>);
                })}

                {extraDescription.length > 0 &&
                <ul>
                    {extraDescription.map((text: string) => {
                        return (<li>{text}</li>);
                    })}
                </ul>
                }

            </div>
            <footer>
                <p>
                    {!isInCart ?
                        <button type="button" onClick={() => {
                            addCartItem(product, quantity);

                            registerEvent('upsale_product_add', product.id);
                        }}><i
                            className="icon-arrow-right"></i> {texts[lang].addToMyOrder} <i
                            className="icon-arrow-left"></i></button>
                        : <Fragment>
                            <button type="reset" onClick={() => {
                                removeFromCartItem(product);
                                registerEvent('upsale_product_remove', product.id);
                            }}><i
                                className="icon-x-circle"></i> {texts[lang].removeFromCart}
                            </button>
                            <span>{texts[lang].scrollDown}</span>
                        </Fragment>
                    }
                </p>
            </footer>
        </form>
    )
}
