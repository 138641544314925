import React, {Fragment} from "react";
import StarRating from "./Stars/StarRating";
import {usePage} from "../../../../Provider/PageProvider";
import {useLang} from "../../../../Provider/LangProvider";

export interface IReview {
    name: string;
    description: string;
    rating: number;
    image: string;
    cover?: string;
}

const texts: any = {
    en: {
        reviews: "Reviews",
    },
    es: {
        reviews: "Reseñas",

    },
    de: {
        reviews: "Bewertungen",

    }
}


export default function Reviews() {
    const {reviews} = usePage();
    const {lang} = useLang();
    if (!reviews) {
        return <Fragment/>
    }

    return <Fragment>
        <h2>{texts[lang].reviews}</h2>
        <ul className="list-reviews">
            {reviews.map((item: IReview, index: number) => {


                return (
                    <li key={"review-" + index}>
                        {item.cover && <img src={item.cover} alt={item.name} className={"review-cover"}/>}
                        <h3>
                            <img src={item.image} alt={item.name} width="50" height="50"/> {item.name}
                            <StarRating rating={item.rating}/>
                        </h3>
                        <p>{item.description}</p>
                    </li>
                )
            })}
        </ul>
    </Fragment>
}
