// @ts-nocheck
import React, {Fragment} from "react";
import {Elements} from '@stripe/react-stripe-js';
import CardNumberField from "./Fields/CardNumberField";
import ExpirationField from "./Fields/ExpirationField";
import CvcField from "./Fields/CvcField";
import StripeCheckoutHandler from "./StripeCheckoutHandler";
import {usePage} from "../../../../../Provider/PageProvider";

interface Props {
    active: boolean;
}

export default function StripeCheckoutWrapper({active}: Props) {

    const {stripePromise} = usePage();

    if (!stripePromise) {
        return <Fragment/>
    }

    return <Elements stripe={stripePromise}>
        <CardNumberField/>
        <ExpirationField/>
        <CvcField/>
        <StripeCheckoutHandler active={active}/>
    </Elements>
}
