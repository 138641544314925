import React from "react";
import {useLang} from "../../../../../Provider/LangProvider";

interface Props {
    email?: string;
}

const texts: any = {
    en: {
        needHelp: "Need help?",
        copyright: "Copyright"
    },
    es: {
        needHelp: "¿Necesitas ayuda?",
        copyright: "Copyright"
    },
    de: {
        needHelp: "Brauchen Sie Hilfe?",
        copyright: "Copyright"
    },
}

export default function FooterNeedHelpComponent(props: Props) {
    const {email} = props;
    const {lang} = useLang();

    const year = (new Date()).getFullYear();

    return (
        <footer id="footer">
            <p>&copy; <span className="date">{year}</span> {texts[lang].copyright}</p>
            <p><a href={"mailto:" + email}><i className="icon-chat"></i> {texts[lang].needHelp}</a></p>
        </footer>
    )
}
