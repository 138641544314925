import React, {useContext, useState} from "react";
import PagesApi from "../Service/PagesApiService";

export interface PageViewData {
    css: string,
    fontUrl: string,

    allScripts: string,
    allScriptsUrl: string[],

    favicon: string,

    title: string,

    pageScripts: string,
    pageScriptsUrl: string[],
}

interface ProviderValue {
    onPageView: (pageData: PageViewData, title: string) => void,
    registerEvent: (name: string, value: number) => void,
}

export const PageViewContext = React.createContext<ProviderValue>({
    onPageView: (pageData: PageViewData, title: string) => {
    },
    registerEvent: (name: string, value: number) => {
    }
})

interface Props {
    children: any,
}

export const usePageView = () => useContext(PageViewContext);

export const PageViewProvider = (props: Props) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const registerEvent = (name: string, value: number) => {
        try {
            // @ts-ignore
            // window.gtag('event', name, {
            //     "value": value,
            // });
        } catch (e) {

        }
    }

    const addJsToDocument = (js: string) => {
        const script = document.createElement('script');
        script.src = js;
        document.head.appendChild(script);
    }
    const addInlineJsToDocument = (js: string) => {
        const script = document.createElement('script');
        script.innerHTML = js;
        document.head.appendChild(script);
    }

    const addCssToDocument = (css: string) => {
        const style = document.createElement('style')
        style.innerText = css
        document.head.appendChild(style)
    }
    const addFontUrlLink = (url: string) => {
        const l1 = document.createElement('link')
        l1.rel = "preload"
        l1.as = "style"
        l1.href = url
        document.head.appendChild(l1)

        const l2 = document.createElement('link')
        l2.rel = "stylesheet"
        l2.media = "screen"
        l2.href = url
        document.head.appendChild(l2)
    }

    const onPageView = (pageData: PageViewData, title: string) => {
        if (!isLoaded) {
            if (pageData.fontUrl) {
                addFontUrlLink(pageData.fontUrl);
            }
            if (pageData.css) {
                addCssToDocument(pageData.css);
            }
            PagesApi.setFavicon(pageData.favicon);

            if (pageData.allScriptsUrl) {
                pageData.allScriptsUrl.map((script: string) => {
                    addJsToDocument(script);
                    return script;
                })
            }
            if (pageData.allScripts) {
                addInlineJsToDocument(window.atob(pageData.allScripts));
            }

            setIsLoaded(true);
        }

        if (pageData.pageScriptsUrl) {
            pageData.pageScriptsUrl.map((script: string) => {
                addJsToDocument(script);
                return script;
            })
        }
        if (pageData.pageScripts) {
            addInlineJsToDocument(window.atob(pageData.pageScripts));
        }
        document.title = pageData.title;


        try {
            // @ts-ignore
            window.gtag('event', 'page_view', {
                page_title: title + ' - ' + pageData.title,
                page_path: window.location.pathname,
                // @ts-ignore
                send_to: window.checkougGtag
            })
        } catch (e) {

        }
    }

    return (
        <PageViewContext.Provider value={{
            onPageView,
            registerEvent
        }}>
            {props.children}
        </PageViewContext.Provider>
    )
}
