import React, {Fragment} from "react";

interface Props {
    rating: number,
    label?: string,
    asP?: boolean,
}

export default function StarRating(props: Props) {
    const fillStars = Array(props.rating).fill(0).map((e, i) => i + 1);
    const grayStars = Array(5 - props.rating).fill(0).map((e, i) => i + 1);

    const innerRating = <span className="rating">
                {fillStars.map((i: number, index: number) => {
                    return (<span key={"fillstar" + index} className="star">
                                <span className="fill" style={{width: '100%'}}></span>
                            </span>)
                })}

        {grayStars.map((i: number, index: number) => {
            return (<span key={"graystar" + index} className="star">
                                <span className="fill" style={{}}></span>
                            </span>)
        })}
            </span>;

    const inner = <Fragment>
        {innerRating}
        {props.label && <span className="label">{props.label}</span>}
    </Fragment>

    if (props.asP) {
        return (
            <p className="rating-a" data-val={props.rating} data-of="5">
                {inner}
            </p>
        )
    }

    return (
        <span className="rating-a" data-val={props.rating} data-of="5">
            {inner}
        </span>
    )
}