import React from "react";
import {isMobileOnly} from 'react-device-detect';
import ListLogo from "./ListLogo";
import HeaderLogo from "./HeaderLogo";
import SslSecurePayment from "./SslSecurePayment";

export default function HeaderComponent() {
    return (
        isMobileOnly ? <header id="top">
                <ListLogo/>
                <HeaderLogo/>
                <SslSecurePayment/>

            </header> :
            <header id="top">
                <HeaderLogo/>
                <SslSecurePayment/>
                <ListLogo/>
            </header>
    )
}
