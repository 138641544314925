import React from "react";
import {CartProvider} from "../../../../../Provider/CartProvider";
import UpsaleContent from "./UpsaleContent";


export default function UpsalePage() {

    return (
        <CartProvider>
            <UpsaleContent/>
        </CartProvider>
    )
}
