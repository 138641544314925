// @ts-nocheck
import React, {Fragment} from "react";
import {CardCvcElement} from "@stripe/react-stripe-js";
import ReactTooltip from "react-tooltip";
import {useLang} from "../../../../../../Provider/LangProvider";

const texts: any = {
    en: {
        tip: "Security code usually found on the back of your card",
    },
    es: {
        tip: "Código de seguridad (suele estar en el reverso de la tarjeta)",
    },
    de: {
        tip: "Der Sicherheitscode befindet sich normalerweise auf der Rückseite Ihrer Karte",
    },
}

export default function CvcField() {
    const {lang} = useLang();

    return (
        <p className={"focus"} style={{position: 'relative'}}>
            <Fragment><span className="tip"
                            data-tip={texts[lang].tip}></span><ReactTooltip/></Fragment>
            {/*<label htmlFor="lpd">CVC</label>*/}
            <label htmlFor={"cc-cvc"}>&nbsp;</label>
            <input type="text" className="cc-cvc" id={"cc-cvc"} readOnly={true}/>
            <div style={{position: 'absolute', top: 24, left: 24, right: 0, zIndex: 10}}>
                <CardCvcElement options={{
                    style: {
                        base: {
                            backgroundColor: "rgba(0,0,0,0)",
                            fontSize: "20px",
                            color: "rgb(47, 47, 47)",
                            '::placeholder': {
                                color: "rgba(47, 47, 47, 0.3)",
                            },
                        },
                    },
                    hidePostalCode: true,
                }}/>
            </div>
        </p>
    )
}
