// @ts-nocheck
import React, {Fragment} from "react";
import {usePage} from "../../../../Provider/PageProvider";
import {usePageView} from "../../../../Provider/PageViewProvider";
import PlaceOrderImg from "../../../../Assets/place_order.png";
import {useLang} from "../../../../Provider/LangProvider";

const texts: any = {
    en: {
        submitting: "Submitting...",
        placeTheOrder: "Place the order",
    },
    es: {
        submitting: "Enviar...",
        placeTheOrder: "Realizar el pedido",
    },
    de: {
        submitting: "Übermitteln...",
        placeTheOrder: "Bestellung aufgeben",
    }
}

export default function PlaceOrder() {
    const {registerEvent} = usePageView();
    const {completeOrder, isProcessing} = usePage();
    const {lang} = useLang();

    const onPress = () => {
        registerEvent('place_order', 1);
        completeOrder();
    }

    return (
        <Fragment>
            <p>
                <button type="button" onClick={onPress}><i
                    className="icon-lock"></i> {isProcessing ? texts[lang].submitting : texts[lang].placeTheOrder}</button>
            </p>
            <p className={"place-order-img"}>
                <img src={PlaceOrderImg} alt={""}/>
            </p>
        </Fragment>
    )
}
