import React from "react";
import {useLang} from "../../../../Provider/LangProvider";

const texts: any = {
    en: {
        sslSecurePayment: "SSL secure payment",
        infoProtected: "Your information is protected by 256-bit SSL encryption"
    },
    es: {
        sslSecurePayment: "PAGO SEGURO SSL",
        infoProtected: "Información  protegida por cifrado SSL de 256 bits"
    },
    de: {
        sslSecurePayment: "SSL SICHERE ZAHLUNG",
        infoProtected: "Ihre Daten sind durch 256-Bit-SSL-Verschlüsselung geschützt"
    },
}

export default function SslSecurePayment() {
    const {lang} = useLang();
    return (
        <p className="s14">
            <span className="strong overlay-c"><i className="icon-lock"></i> {texts[lang].sslSecurePayment}</span>
            {texts[lang].infoProtected}
        </p>
    )
}
