import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {usePageView} from "../../../../../Provider/PageViewProvider";
import {usePage} from "../../../../../Provider/PageProvider";
import {useLang} from "../../../../../Provider/LangProvider";

interface Props {
    className?: string,
}

const texts: any = {
    en: {
        noThanks: "No, Thanks I don't want to take advantage of this exclusive offer",
    },
    es: {
        noThanks: "NO, GRACIAS. NO QUIERO APROVECHAR ESTA OFERTA EXCLUSIVA",
    },
    de: {
        noThanks: "NEIN DANKE, ICH MÖCHTE DIESES EXKLUSIVE ANGEBOT NICHT WAHRNEHMEN",
    },
}


export default function UpsaleCancelButton({className}: Props) {
    const {registerEvent} = usePageView();

    const {lang} = useLang();

    const text = texts[lang].noThanks;

    const history = useHistory();
    const {serial} = useParams();

    const goToThankYouPage = () => {
        history.push('/order/confirm/' + serial);

        registerEvent('upsale_cart_no_thanks', 1);
    }

    return (
        <p className={"s18 text-center text-uppercase strong " + (className ? className : '')}>
            <a className="overlay-b" href="/cancel" onClick={e => {
                e.preventDefault();
                goToThankYouPage();
            }}>{text}</a></p>
    )
}
