// @ts-nocheck
import React from "react";
import {PageProvider} from "../Provider/PageProvider";


export default function () {

// const old = <Experiment id="55H-f-EOTgyvoGATF-L6AQ">
//     <Variant id="0">
//         <PageProvider url={'d-1'}/>
//     </Variant>
//     <Variant id="1">
//         <PageProvider url={'d-2'}/>
//     </Variant>
// </Experiment>

    return (
        <PageProvider url={'catsizor'}/>
    )

}
