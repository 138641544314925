import Config from "../Constants/Config";

class PaymentsApiService {
    get completeOrder(): () => void {
        return this._completeOrder;
    }

    set completeOrder(value: () => void) {
        this._completeOrder = value;
    }

    private _completeOrder: () => void;

    set token(value: string) {
        this._token = value;
    }

    private _token: string;

    constructor() {
        this._token = '';
        this._completeOrder = () => {
        }
    }

    defaultRequestOptions = () => {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this._token
            },
        }
    };

    getPaymentMethods() {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(Config.backendUrl + '/test/payments', requestOptions).then(res => res.json());
    }

    makePayment(data: any) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({data: data})
        };

        return fetch(Config.backendUrl + '/test/makePayment', requestOptions).then(res => res.json());
    }

    buyMore(data: any) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({data: data})
        };

        return fetch(Config.backendUrl + '/test/buyMore', requestOptions).then(res => res.json());
    }
}

const PaymentsApi = new PaymentsApiService();
export default PaymentsApi;
