import React, {Fragment} from "react";
import {UpgradeOrderProduct} from "../../../../../Types/Objects";
import Product from "./Product";
import {usePage} from "../../../../../Provider/PageProvider";
import {useLang} from "../../../../../Provider/LangProvider";

const texts : any = {
    en: {
        upgradeYourOrder: "Upgrade your order"
    },
    es: {
        upgradeYourOrder: "ACTUALIZA TU PEDIDO"
    },
    de: {
        upgradeYourOrder: "AKTUALISIEREN SIE IHRE BESTELLUNG"
    },
}

export default function UpgradeOrder() {
    const {upgradeProducts} = usePage();
    const {lang} = useLang();

    const products = upgradeProducts;

    if (!products || products.length === 0) {
        return <Fragment/>
    }

    return (
        <Fragment>
            <h2>{texts[lang].upgradeYourOrder}</h2>
            <ul className="list-upgrade">
                {products.map((item: UpgradeOrderProduct, index: number) => {
                        return <Product key={"upgrade-order-product-" + item.id} product={item}/>
                    }
                )}
            </ul>
        </Fragment>
    )
}
