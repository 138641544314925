import React, {Fragment} from "react";
import ProductBump from "./ProductBump";
import {Product} from "../../../../../Types/Objects";
import {usePage} from "../../../../../Provider/PageProvider";
import {isMobileOnly} from 'react-device-detect';
import {usePageView} from "../../../../../Provider/PageViewProvider";
import {useLang} from "../../../../../Provider/LangProvider";
import transparentImg from "../../../../../Assets/transparent.png"

interface Props {
    product: Product;
}

const isQuiz = () => {
    return window.location.host === '-checkout.myteethacademy.com' || window.location.host === 'checkout.dogfasting.com';
}

const isPraytify = () => {
    return window.location.host === 'checkout.praytify.com' || window.location.host === 'checkout.blessify.com';
}

const texts: any = {
    en: {
        mostPopular: "Most Popular",
        reg: "Reg",
        only: "Only"
    },
    es: {
        mostPopular: "Lo más populuar",
        reg: "Reg",
        only: "Solo"
    },
    de: {
        mostPopular: "Am beliebtesten",
        reg: "Reg",
        only: "Nur"
    },
}

export default function ProductItem({product}: Props) {
    const {registerEvent} = usePageView();
    const {selectedProduct, setSelectedProduct, isProductChanged, setProductChanged, currencySymbol} = usePage();
    const {lang} = useLang();

    const selected = selectedProduct === product;

    const onClick = () => {
        if (!isProductChanged && selected) {
            setProductChanged(true);
        }
    }

    const getRegPrice = (isAlignLeft: boolean) => {
        let priceStr = product.price.toFixed(2);
        let regularPriceStr = product.regularPrice.toFixed(2);

        if (isQuiz()) {
            let period = 4;
            if (product.title.indexOf('Annual') >= 0 || product.title.indexOf('annual') >= 0) {
                period = 52;
            } else if (product.title.indexOf('6 Month') >= 0 || product.title.indexOf('6 month') >= 0) {
                period = 26;
            } else if (product.title.indexOf('3 Month') >= 0 || product.title.indexOf('3 month') >= 0) {
                period = 13;
            }
            const priceW = Math.round(product.price / period * 100) / 100;
            const regularPriceW = Math.round(product.regularPrice / period * 100) / 100;

            priceStr = priceW.toFixed(2) + "/week";
            regularPriceStr = regularPriceW.toFixed(2) + "/week";
        }

        if (isPraytify()) {
            let period = 12;

            if (product.title.indexOf('6 Month') >= 0 || product.title.indexOf('6 month') >= 0) {
                period = 6;
            }

            const priceW = Math.round(product.price / period * 100) / 100;
            const regularPriceW = Math.round(product.regularPrice / period * 100) / 100;

            priceStr = priceW.toFixed(2) + "/month";
            regularPriceStr = regularPriceW.toFixed(2) + "/month";
        }


        return (
            <Fragment>
                {texts[lang].only} {currencySymbol}{priceStr}
                <span
                    style={isAlignLeft ? {textAlign: 'left'} : {}}>{texts[lang].reg} {currencySymbol}{regularPriceStr}</span>
            </Fragment>
        )
    }

    return (
        <li onClick={onClick}>
            {isMobileOnly && product.isPopular &&
            <span className={"most-pop-product-wrap"}>
                <span className="most-pop-product"><i className="icon-flame"></i> {product.hotText?product.hotText:texts[lang].mostPopular}</span>
                <span className={"triangle"}>
                    <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 -4.80825e-07L11 0L5.5 6L0 -4.80825e-07Z" fill="#F06565"/>
</svg>

                </span>
            </span>
            }
            <input type="radio" id={"product-" + product.id} name="lca" value={product.id}
                   onChange={() => {
                       setSelectedProduct(product);
                       registerEvent('product_select', product.id);
                   }} checked={selected}/>
            <label htmlFor={"product-" + product.id} className={isMobileOnly ? "mobile-only" : ""}>
                {isMobileOnly ?
                    <Fragment>
                        <span className="row" style={{marginBottom: 2}}>
                            <span className="overlay-a">{product.title}</span>
                        </span>
                        {!!product.description &&
                        <span className="row" style={{marginBottom: 2, paddingLeft: 35}}>
                            <span className="overlay-a product-description">
                                {product.description.split("\n").map((t: string, index: number) =>
                                    <Fragment key={"prt-" + product.productId + "-" + index}>{t}<br/></Fragment>)}
                            </span>
                        </span>
                        }

                        <span className={"row"} style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                            <p className="price"
                               style={{marginLeft: 33}}>{getRegPrice(true)}
                            </p>
                            {!!product.image &&
                            <img src={product.image} alt={product.title} width="220" height="165"/>
                            }
                        </span>
                        {/*<span className="row">*/}
                        {/*    <span className="overlay-a">{product.description}</span>*/}
                        {/*    {product.isPopular &&*/}
                        {/*    <span className="overlay-b"><i className="icon-flame"></i> Most Popular</span>*/}
                        {/*    }*/}
                        {/*    <span></span>*/}
                        {/*</span>*/}
                        {/*<span className="row">*/}
                        {/*    <img src={product.image} alt={product.title} width="220" height="165"/>*/}
                        {/*    <span className="title">{product.title}</span>*/}
                        {/*    <p className="price">Only ${product.price.toFixed(2)}*/}
                        {/*        <span>Reg ${product.regularPrice.toFixed(2)}</span>*/}
                        {/*    </p>*/}
                        {/*</span>*/}
                    </Fragment>

                    :

                    <Fragment>
                        {!!product.image ?
                            <img src={product.image} alt={product.title} width="220" height="165"/>
                            :
                            <img src={transparentImg} alt={product.title} width="50" height="50" className={"no-img"}/>
                        }
                        <span>
                            <span className="title">{product.title}</span>
                            <span
                                className="overlay-a product-description" style={{whiteSpace: "normal"}}>{product.description.split("\n").map((t: string, index: number) =>
                                <Fragment key={"prt-" + product.productId + "-" + index}>{t}<br/></Fragment>)}</span>
                        </span>
                        {product.isPopular &&
                        <span className="overlay-b"><i className="icon-flame"></i> {product.hotText?product.hotText:texts[lang].mostPopular}</span>
                        }
                    </Fragment>
                }

            </label>
            {!isMobileOnly &&
            <p className="price">{getRegPrice(false)}
            </p>}
            {product.bumpProduct && isProductChanged &&
            <ProductBump product={product.bumpProduct} orderProduct={product}/>
            }
        </li>
    )
}
