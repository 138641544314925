// @ts-nocheck
import React from "react";
import {CardNumberElement} from "@stripe/react-stripe-js";
import {usePage} from "../../../../../../Provider/PageProvider";
import {useLang} from "../../../../../../Provider/LangProvider";

const texts : any = {
    en: {
        cardNumber: "Card number"
    },
    es: {
        cardNumber: "Número de tarjeta"
    },
    de: {
        cardNumber: "Kartennummer"
    },
}

export default function CardNumberField() {
    const {formError} = usePage();
    const {lang} = useLang();

    return (
        <p className={"focus"} style={{position: 'relative'}}>
            {/*<label htmlFor="lpb">Card Number</label>*/}
            <label htmlFor={"cc-num"}>&nbsp;</label>
            <input type="text" id={"cc-num"} className="cc-num" readOnly={true}/>
            {formError && <label className="error">{formError}</label>}
            <div style={{position: 'absolute', top: 24, left: 24, right: 0, zIndex: 10}}>
                <CardNumberElement options={{
                    style: {
                        base: {
                            backgroundColor: "rgba(0,0,0,0)",
                            fontSize: "20px",
                            color: "rgb(47, 47, 47)",
                            '::placeholder': {
                                color: "rgba(47, 47, 47, 0.3)",
                            },
                        },
                    },
                    hidePostalCode: true,
                    placeholder: texts[lang].cardNumber
                }}/>
            </div>
        </p>
    )
}
