import React from 'react'
import {useLang} from "../../../../Provider/LangProvider";
import {usePage} from "../../../../Provider/PageProvider";
import parse from "html-react-parser";

interface Props {
    popupHtml: any,
    setAcceptPopupOffer: (val: boolean) => void;
    closePopup: () => void;
}

const texts: any = {
    en: {
        upgradeYourOrder: "Upgrade Your Order With One Click. Special Deals For Add‑ons Only Now!",
        submitting: "Submitting...",
        placeTheOrder: "UPGRADE YOUR ORDER",
        youHaveSelected: "You Have Selected",
        regularDeal: 'I WANT MY REGULAR DEAL',
    },
    es: {
        upgradeYourOrder: "Actualice su pedido con un clic. ¡Ofertas especiales para complementos solo ahora!",
        submitting: "Enviar...",
        placeTheOrder: "Realizar el pedido",
        youHaveSelected: "Has seleccionado",
        regularDeal: 'I WANT MY REGULAR DEAL',
    },
    de: {
        upgradeYourOrder: "Upgraden Sie Ihre Bestellung mit einem Klick. Sonderangebote für Add-Ons erhalten Sie nur jetzt!",
        submitting: "Übermitteln...",
        placeTheOrder: "Bestellung aufgeben",
        youHaveSelected: "Du hast ausgewählt",
        regularDeal: 'I WANT MY REGULAR DEAL',
    }
}


export default function Popup(props: Props) {
    const {lang} = useLang();
    const {completeOrder, isProcessing} = usePage();
    const onConfirm = () => {
        props.setAcceptPopupOffer(true);
        props.closePopup();

        setTimeout(() => {
            completeOrder();
        }, 300);
    }

    const onCancel = (e: any) => {
        e.preventDefault();
        props.closePopup();
        completeOrder();
    }
    return (
        <div className={"popup-wrapper"}>
            <div className={"popup-inner"}>
                <div className={"popup"}>
                    {/*<button className={"popup-close"} onClick={props.closePopup}>X</button>*/}
                    {!!props.popupHtml && parse(props.popupHtml)}
                    <p>
                        <button type="button" onClick={onConfirm}>
                            {isProcessing ? texts[lang].submitting : texts[lang].placeTheOrder}
                        </button>
                    </p>
                    <p className={"s18 text-center text-uppercase strong "}>
                        <a className="overlay-b" href="/cancel" onClick={onCancel}>{texts[lang].regularDeal}</a>
                    </p>
                </div>
            </div>
        </div>
    )
}
