import React, {Fragment, useEffect, useState} from "react";
import {usePage} from "../../../../../Provider/PageProvider";
import {useLang} from "../../../../../Provider/LangProvider";

interface Props {
    title: string,
    fieldKey: string,
    val: string,
    onChange: (key: string, val: string) => void,
    required?: boolean,
    onRef?: any,
    tipText?: string,
    errorText?: string,
    type?: string,
    onFieldBlur?: () => void
}

const texts : any = {
    en: {
        defaultErrorText: "This field cannot be left blank"
    },
    es: {
        defaultErrorText: "Este campo no puede dejarse en blanco"
    },
    de: {
        defaultErrorText: "Dieses Feld darf nicht leer gelassen werden"
    },
}

export default function Field({
                                  title,
                                  fieldKey,
                                  required,
                                  val,
                                  onChange,
                                  onRef,
                                  tipText,
                                  errorText,
                                  onFieldBlur,
                                  type = "text"
                              }: Props) {
    const [isFocus, setFocus] = useState(false);
    const [isError, setError] = useState(false);
    const {saveContactDetails} = usePage();
    const {lang} = useLang();

    let pClassName = [];
    if (val.length > 0 || isFocus) {
        pClassName.push("focus");
    }
    if (isError) {
        pClassName.push("has-error");
    }
    const onBlur = () => {
        setFocus(false);
        checkError();
        saveContactDetails();
        if (onFieldBlur) {
            onFieldBlur();
        }
    }

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(email.trim());

    }

    const checkError = () => {
        if (type === 'email') {
            if (!validateEmail(val)) {
                setError(true);
                return;
            }
        }
        if (required && val.length === 0) {
            setError(true);
        } else {
            setError(false);
        }
    }

    useEffect(() => {
        if (isFocus) {
            checkError();
        }
    }, [val]);

    useEffect(() => {
        document.addEventListener("checkError", checkError);
        return () => {
            document.removeEventListener("checkError", checkError);
        }
    }, [val])

    const htmlKey = "f-" + fieldKey;

    return (
        <p className={pClassName.join(" ")}>
            {!!tipText && <Fragment><span className="tip" data-tip={tipText}></span></Fragment>}
            <label htmlFor={htmlKey}>{title}</label>
            <input type="text" id={htmlKey} name={htmlKey} required={required} value={val}
                   onChange={(e) => onChange(fieldKey, e.target.value)} onFocus={() => setFocus(true)} onBlur={onBlur}
                   ref={onRef}
                   inputMode={fieldKey === 'phone' ? 'tel' : 'text'}
            />
            {isError &&
            <label className="error"
                   htmlFor={htmlKey}>{errorText ? errorText : texts[lang].defaultErrorText}</label>}
        </p>
    )
}
