import React, {Fragment} from "react";
import ProductItem from "./ProductItem";
import {usePage} from "../../../../../Provider/PageProvider";
import {useLang} from "../../../../../Provider/LangProvider";

const isQuiz = () => {
    return window.location.host === '-checkout.myteethacademy.com' || window.location.host === 'checkout.dogfasting.com';
}

const isTeeth = () => {
    return window.location.host === 'checkout.myteethacademy.com';
}

const isPraytify = () => {
    return window.location.host === 'checkout.praytify.com' || window.location.host === 'checkout.blessify.com';
}

const texts: any = {
    en: {
        step1: "Step 1",
        selectQuantity: isQuiz() || isPraytify() ? "SELECT PLAN" : (isTeeth() ? "SELECT PRODUCT" : "Select Order Quantity")
    },
    es: {
        step1: "PASO 1",
        selectQuantity: "SELECCIONA LA CANTIDAD"
    },
    de: {
        step1: "SCHRITT 1",
        selectQuantity: "BESTELLMENGE AUSWÄHLEN"
    },
}

export default function OrderQuantity() {
    const {products} = usePage();
    const {lang} = useLang();

    return (
        <Fragment>
            <h2>{texts[lang].step1}: <span>{texts[lang].selectQuantity}</span></h2>
            <ul className="list-cart">
                {products.map(item => {
                    return <ProductItem key={"product-item-" + item.id} product={item}/>
                })}
            </ul>
        </Fragment>
    )
}
