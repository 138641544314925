import React from "react";

interface Props {
    image: string,
}

export default function HeaderBackground({image}: Props) {
    return (
        <figure id="background">
            <img src={image} alt="" width="1365" height="675"/>
        </figure>
    )
}
