// @ts-nocheck
import React, {Fragment, useEffect} from "react";
import PaymentsApi from "../../../../../Service/PaymentsApiService";
import {usePage} from "../../../../../Provider/PageProvider";
import {usePageView} from "../../../../../Provider/PageViewProvider";

interface Props {
    active: boolean;
}

export default function PaypalCheckoutWrapper({active}: Props) {
    const {registerEvent} = usePageView();
    const {makeOrder, contact, address, cartItems} = usePage();
    const doCompleteOrder = () => {
        if (active) {
            PaymentsApi.completeOrder = () => {
                doCheckout();
            };
        }
    };

    useEffect(doCompleteOrder, [active, contact, address, cartItems])

    const doCheckout = () => {
        registerEvent('paypal_order_start', 1);

        makeOrder('paypal').then((res) => {
            if (res.success === 1) {
                registerEvent('paypal_order_success', 1);

                const links = res.data.result.links;
                const approveLinks = links.filter(item => item.rel === 'approve');
                if (approveLinks.length > 0) {
                    window.location.href = approveLinks[0].href;
                }
            }
        })
    }


    return (
        <Fragment/>
    )
}
