import React from "react";

interface Props {
    image: string,
    title: string,
}

export default function HeaderLogoTitle({image, title}: Props) {
    return (
        <header id="top" className="text-center" data-count="10">
            <p id="logo"><a href="/" title={"Home"}><img src={image} alt={title} width="125" height="75"/></a></p>
            {title && <h1>{title}</h1>}
        </header>
    )
}
