import React from "react";
import {usePage} from "../../../../Provider/PageProvider";

export default function HeaderLogo() {
    const {mainLogoUrl} = usePage();
    return (
        <p id="logo">
            <a href="..">
                <img src={mainLogoUrl} alt="" width="125" height="75"/>
            </a>
        </p>
    )
}
