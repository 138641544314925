// @ts-nocheck
import React from "react";
import {CardExpiryElement} from "@stripe/react-stripe-js";

export default function ExpirationField() {
    return (
        <p className={"focus"} style={{position: 'relative'}}>
            <label htmlFor={"cc-exp"}>&nbsp;</label>
            <input type="text" className="cc-exp" id={"cc-exp"} readOnly={true}/>
            <div style={{position: 'absolute', top: 24, left: 24, right: 0, zIndex: 10}}>
                <CardExpiryElement options={{
                    style: {
                        base: {
                            backgroundColor: "rgba(0,0,0,0)",
                            fontSize: "20px",
                            color: "rgb(47, 47, 47)",
                            '::placeholder': {
                                color: "rgba(47, 47, 47, 0.3)",
                            },
                        },
                    },
                    hidePostalCode: true,
                }}/>
            </div>
        </p>
    )
}
