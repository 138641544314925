import React, {useState} from "react";
import ReactPlayer from "react-player";

interface Props {
    videoUrl: string
}

export default function UpsaleVideoPlayer(props: Props) {
    const [playing, setPlaying] = useState(true);
    const togglePlaying = () => setPlaying(!playing);

    return (
        <div onClick={togglePlaying}>
            <div className={"youtube-wrapper"}>
                <ReactPlayer url={props.videoUrl} playing={playing} loop={true}/>
            </div>
        </div>
    )
}
