import React, {useEffect} from "react";

import {Countries} from "../../../../../Constants/Countries";
import FormsApi from "../../../../../Service/FormsApiService";
import {usePage} from "../../../../../Provider/PageProvider";
import {useLang} from "../../../../../Provider/LangProvider";

interface Props {
    val: string;
}

interface Country {
    name: string;
    code: string;
}

const texts : any = {
    en: {
        country: "Country"
    },
    es: {
        country: "País"
    },
    de: {
        country: "Staat"
    },
}

export default function CountryField({val}: Props) {
    const {onAddressChange} = usePage();
    const {lang} = useLang();

    const init = () => {
        FormsApi.init().then(res => {
            if (res.country) {
                const address = {
                    'country': res.country,
                    'city': res.city,
                    'state': res.state,
                }
                onAddressChange('multiple', JSON.stringify(address));
            }
        });
    }

    useEffect(init, []);

    return <p className={"focus"}>
        <label htmlFor="country">{texts[lang].country}</label>
        <select value={val} onChange={(e) => onAddressChange('country', e.target.value)} id={"country"}>
            {Countries.map((item: Country) => {
                return (<option key={'country-' + item.code} value={item.code}>{item.name}</option>)
            })}
        </select>

    </p>
}
