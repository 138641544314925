// @ts-nocheck
import React from "react";
import {PageProvider} from "../Provider/PageProvider";


export default function () {

    return (
        <PageProvider url={'LEDchargy'}/>
    )

}
