import React, {Fragment} from "react";
import Field from "./Fields/Field";
import CountryField from "./Fields/CountryField";
import {usePage} from "../../../../Provider/PageProvider";
import PagesApi from "../../../../Service/PagesApiService";
import {useLang} from "../../../../Provider/LangProvider";
import StateField from "./Fields/StateField";

const isTeeth = () => {
    return window.location.host === '-checkout.myteethacademy.com';
}

const texts: any = {
    en: {
        step3: "Step 3",
        deliveryAddress: isTeeth() ? "DIPLOMA MAILING ADDRESS" : "Delivery Address",
        streetAddress: "Street Address",
        zip: "Zip / Postal Code",
        state: "State / Province",
        city: "Town / City",
    },
    es: {
        step3: "PASO 3",
        deliveryAddress: "DIRECCIÓN DE ENTREGA",
        streetAddress: "Dirección",
        zip: "Código postal",
        state: "Estado / provincia",
        city: "Estado / provincia",
    },
    de: {
        step3: "SCHRITT 3",
        deliveryAddress: "LIEFERADRESSE",
        streetAddress: "Straße",
        zip: "Postleitzahl",
        state: "Staat / Provinz",
        city: "Stadt",
    },
}

export default function DeliveryAddress() {
    const {address, onAddressChange, url} = usePage();
    const {lang} = useLang();

    if (!address) {
        return <Fragment/>
    }

    const onFieldBlur = () => {
        PagesApi.checkZip(address['zip'], address['country']).then(res => {
            if ('state' in res && 'city' in res && !!res.state && !!res.city) {
                onAddressChange('multiple', JSON.stringify(res));
            }
        })
    }

    return (
        <Fragment>
            <h2>{texts[lang].step3}: <span>{texts[lang].deliveryAddress}</span></h2>
            <CountryField val={address.country}/>
            <Field val={address.street} onChange={onAddressChange} title={texts[lang].streetAddress} fieldKey={"street"}
                   required={true}/>

            <div className="double a">
                <Field val={address.zip} onChange={onAddressChange} onFieldBlur={onFieldBlur} title={texts[lang].zip}
                       fieldKey={"zip"} required={true}/>

                {/*{url === 'lucky-test' ? <StateField/> :*/}
                {/*    <Field val={address.state} onChange={onAddressChange} title={texts[lang].state} fieldKey={"state"}*/}
                {/*           required={true}/>}*/}
                <StateField/>

            </div>

            <Field val={address.city} onChange={onAddressChange} title={texts[lang].city} fieldKey={"city"}
                   required={true}/>
        </Fragment>
    )
}
