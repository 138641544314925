import React, {Fragment, useEffect, useState} from "react";
import {LangProvider} from "../Provider/LangProvider";
import HeaderBackground from "../Themes/GreenPixel/Components/Content/Header/HeaderBackground";
import HeaderLogoTitle from "../Themes/GreenPixel/Components/Content/Header/HeaderLogoTitle";
import EmailImage from "../Assets/email-us.png";
import FooterNeedHelpComponent from "../Themes/GreenPixel/Components/Content/Footer/FooterNeedHelpComponent";
import {useParams} from "react-router-dom";
import {DetailsResponse} from "../Types/Upsale";
import PagesApi from "../Service/PagesApiService";
import {CartTotalItem} from "../Types/Objects";

const texts: any = {
    en: {
        youJustGot: "You Just Got A Great Deal!",
        orderReceipt: "Order receipt",
        yourProduct: "Your Product Receipt",
        item: "Item",
        amount: "Amount",
        total: "Total",
        thankYou: "Thank You for Your Order!",
        confMail: "A confirmation email with more information will also be sent to your email address!",
        confMail2: "P.S. If you don't see anything in your inbox, please don't forget to check your junk/spam folder.",
        confMail3: "We hope you love your",
        confMail4: "however if you’re not completely satisfied, please reach out to us via email and we will assist you!",
        confMail5: "THANK YOU: We Reach Over 50,000+ Happy Customers in June 2020!",
        gotQuestions: "Got Questions?",
        needAssistance: "Need assistance regarding your order or anything about our products?",
        feelFree: "Feel free to email us",
        assistant: "We are more than willing to assistant you"
    },
    es: {
        youJustGot: "¡Acabas de conseguir una gran oferta!",
        orderReceipt: "Recibo de pedido",
        yourProduct: "Recibo de tu producto",
        item: "Artículo",
        amount: "Cantidad",
        total: "Total",
        thankYou: "¡Gracias por tu pedido!",
        confMail: "¡Enviaremos también una carta de confirmación, con más información, a tu dirección de correo electrónico!",
        confMail2: "PD: Si no lo recibes en tu bandeja de entrada, no olvides revisar tu carpeta de correo no deseado.",
        confMail3: "Esperamos que te guste tu limadora de uñas indolora para mascotas",
        confMail4: " Sin embargo, si no quedas completamente satisfecho, comunícate con nosotros por correo electrónico y te ayudaremos.",
        confMail5: "GRACIAS: ¡Llegamos a más de 50.000 clientes satisfechos en junio de 2020!",
        gotQuestions: "¿Tienes preguntas?",
        needAssistance: "¿Necesitas ayuda con respecto a tu pedido o a alguno de nuestros productos?",
        feelFree: "No dudes en enviarnos un correo electrónico a",
        assistant: "Estamos más que dispuestos a ayudarte."

    },

    de: {
        youJustGot: "Sie haben gerade einen guten Deal gemacht!",
        orderReceipt: "Bestellbeleg",
        yourProduct: "Ihre Quittung/ Rechnung",
        item: "Artikel",
        amount: "Anzahl",
        total: "Gesamt",
        thankYou: "Vielen Dank für Ihre Bestellung!",
        confMail: "Eine Bestätigungs-E-Mail mit weiteren Informationen wird ebenfalls an Ihre E-Mail-Adresse gesendet!",
        confMail2: "P.S. Wenn Sie nichts in Ihrem Posteingang sehen, vergessen Sie bitte nicht, Ihren Junk- / Spam-Ordner zu überprüfen.",
        confMail3: "Wir hoffen, dass Sie Ihren",
        confMail4: "Wenn Sie jedoch nicht vollständig zufrieden sind, wenden Sie sich bitte per E-Mail an uns und wir helfen Ihnen weiter!",
        confMail5: "DANKE: Wir erreichten im Juni 2020 über 50.000 zufriedene Kunden!",
        gotQuestions: "Gibt es Fragen?",
        needAssistance: "Benötigen Sie Unterstützung bei Ihrer Bestellung oder Informationen zu unseren Produkten?",
        feelFree: "Bitte senden Sie uns eine E-Mail an",
        assistant: "Wir helfen Ihnen gerne weiter."
    },
}

const isTeeth = () => {
    return window.location.host === 'checkout.myteethacademy.com';
}

const isDogfasting = () => {
    return window.location.host === 'checkout.dogfasting.com';
}

const isPraytify = () => {
    return window.location.host === 'checkout.praytify.com' || window.location.host === 'checkout.blessify.com';
}


export default function PaymentErrorPage() {
    const {serial} = useParams();
    const [data, setData] = useState<DetailsResponse>();

    useEffect(() => {
        PagesApi.getDetails(serial).then((res: DetailsResponse) => {
            if (res.success === 1) {
                setData(res);
                // console.log('res', res);
                PagesApi.setFavicon(res.data.favicon);


            }
        })
    }, [serial]);

    const gtagPurchase = () => {
        if (!!data && !!data.data) {
            const currencyRate = data.data.currency === 'MXN' ? 0.05 : 1;

            const products = data.items.filter((_i) => _i.quantity > 0 && _i.price > 0).map((item: CartTotalItem, index: number) => {
                return {
                    'id': item.productId === -1 ? 'shipping' : item.productId,
                    'name': item.title,
                    'price': item.price * currencyRate,
                    'quantity': item.quantity,
                    'variant': '',
                    'category': index === 0 ? 'main' : 'other',
                    'coupon': '',
                }
            })
            try {
                const k = 'p-' + serial;
                if (!localStorage.getItem(k)) {
                    localStorage.setItem(k, '1');
                    // @ts-ignore
                    window.dataLayer.push({
                        'event': 'purchase',
                        'PurchaseType': '',
                        'ecommerce': {
                            'purchase': {
                                'actionField': {
                                    'id': serial,
                                    'affiliation': '',
                                    'revenue': (data.total / 100) * currencyRate,
                                    'tax': 0.00,
                                    'shipping': 0.00,
                                    'coupon': ''
                                },
                                'products': products
                            }
                        }
                    });

                }
            } catch (e) {

            }
        }
    }

    const onPageLoad = () => {
        if (data && data.data) {
            gtagPurchase();
        }
    }
    useEffect(onPageLoad, [data]);

    if (!data) {
        return <Fragment/>;
    }

    return (
        <LangProvider lang={"en"}>
            <Fragment>
                <HeaderBackground image={data.data.bg}/>
                <HeaderLogoTitle image={data.data.mainLogoUrl} title={""}/>

                <main id="content">

                    <form className={"form-cart module-product"} style={{background: '#FFF', borderRadius: 10, border: "1px solid #eee"}}>

                        <div style={{width: '100%'}}>
                            <h3>Oopps!</h3>

                            {isTeeth() ? <p>
                                    It looks like our Academy is full right now and we couldn’t accept new applications.
                                    But don’t worry, your card was not charged and we’ll keep you posted about new places
                                    available.</p>
                                :
                                (isPraytify()?
                                <p>We couldn't process your plan as we reached the maximum user number for this quarter. We will get back to you as soon as possible. But don't worry! Your card was not charged.
                                </p>
                                :
                                <p>We couldn't process your dog's plan as all our vets are busy right now. We will get back to you as soon as possible.
                                    But don't worry! Your card was not charged.

                                </p>
                                )
                            }
                        </div>

                    </form>

                    <form className={"form-cart module-product"} style={{background: '#FFF', borderRadius: 10, border: "1px solid #eee"}}>
                        <div className={"text-center"}>
                            <img src={EmailImage} width={150} height={"auto"} alt={""}/>
                        </div>
                        <div>
                            <h3>{texts["en"].gotQuestions}</h3>
                            <p>{texts["en"].needAssistance}<br/>{texts["en"].feelFree} {data.data.email}. {texts["en"].assistant}
                            </p>
                        </div>

                    </form>
                </main>
                <br/>
                <br/>
                <FooterNeedHelpComponent email={data.data.email}/>

            </Fragment>
        </LangProvider>
    )
}
